import React, { useState } from 'react';
import AppDialog from "../../shared/AppDialog";
import { Box, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import UseParentGroups from "../../../hooks/groups/useParentGroups";

function CreateParentDialog({ open, handleClose, unitGroups, fetchParentGroups }) {
    const { saveParentGroup } = UseParentGroups();

    const [parentName, setParentName] = useState('');
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);

    const handleGroupSelection = (groupId) => {
        setSelectedGroupIds((prevSelected) =>
            prevSelected.includes(groupId)
                ? prevSelected.filter((id) => id !== groupId)
                : [...prevSelected, groupId]
        );
    };

    const handleSaveParentGroup = async () => {
        if (!parentName || selectedGroupIds.length === 0) {
            return;
        }

        const response = await saveParentGroup(parentName, selectedGroupIds);
        console.log(response);
        if (response.ok) {
            setParentName('');
            setSelectedGroupIds([]);
            await fetchParentGroups();
            handleClose();
        } else {
            console.error('Failed to save parent group');
        }
    };

    return (
        <AppDialog
            open={open}
            title="Hey there!"
            handleClose={handleClose}
            handleConfirm={handleSaveParentGroup}
            confirmText="Create"
            cancelText="Cancel"
        >
            <Typography mb={1} variant="subtitle2">
                You can create a parent group consisting of your unit groups here. Choose a name and select the groups you want to include.
            </Typography>
            <TextField
                id="group-name"
                label="Group Name"
                variant="standard"
                value={parentName}
                fullWidth
                onChange={(e) => setParentName(e.target.value)}
                InputProps={{
                    sx: {
                        '&:after': {
                            borderBottomColor: '#4EB6B5',
                        },
                    },
                }}
                InputLabelProps={{
                    sx: {
                        '&.Mui-focused': {
                            color: '#37589D',
                            fontWeight: 'bold',
                        },
                    },
                }}
            />
            <Box maxHeight={300} overflow="auto" mt={2}>
                <Typography variant="body2">
                    Which groups to include?
                </Typography>
                {unitGroups.map((group) => (
                    <FormControlLabel
                        key={group.id}
                        control={
                            <Checkbox
                                size="small"
                                sx={{ color: 'success.main',
                                    '&.Mui-checked': {
                                        color: 'success.main',
                                    },}}
                                checked={selectedGroupIds.includes(group.id)}
                                onChange={() => handleGroupSelection(group.id)}
                            />
                        }
                        label={group.name}
                    />
                ))}
            </Box>
        </AppDialog>
    );
}

export default CreateParentDialog;
