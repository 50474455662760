import React, { useState } from 'react';
import AppContainer from "../shared/AppContainer";
import AppHeader from "../shared/AppHeader";
import styled from "@emotion/styled";
import {Box, TextField, Typography} from "@mui/material";
import AppButton from "../shared/AppButton";


// use grid instead of boxes
const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
});

const AddressRow = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

function AddUnitThree({ onSubmit, onLocationChange, onBack }) {
    const [address, setAddress] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_abbreviation: '',
        zipcode: ''
    });

    const handleInputChange = (field, value) => {
        setAddress(prevAddress => ({ ...prevAddress, [field]: value }));
        onLocationChange(prevAddress => ({ ...prevAddress, [field]: value }));
    };

    return (
        <AppContainer style={{ width: '100%' }}>
            <AppHeader title="Units Location" onBack={onBack}  />
            <Typography variant="h6" fontWeight="bold" color="#959292" align="center" mt={1}>
                Please enter in where the unit will be located
            </Typography>
            <FormContainer>
                <AddressRow>
                    <TextField
                        label="Address Line 1"
                        variant="standard"
                        fullWidth
                        value={address.address_line_1}
                        onChange={e => handleInputChange('address_line_1', e.target.value)}
                        InputProps={{ disableUnderline: true, style: { fontSize: '1em', fontWeight: '600' } }}
                    />
                    <TextField
                        label="Address Line 2"
                        variant="standard"
                        fullWidth
                        value={address.address_line_2}
                        onChange={e => handleInputChange('address_line_2', e.target.value)}
                        InputProps={{ disableUnderline: true, style: { fontSize: '1em', fontWeight: '600' } }}
                    />
                </AddressRow>
                <AddressRow>
                    <TextField
                        label="City"
                        variant="standard"
                        fullWidth
                        value={address.city}
                        onChange={e => handleInputChange('city', e.target.value)}
                        InputProps={{ disableUnderline: true, style: { fontSize: '1em', fontWeight: '600' } }}
                    />
                    <TextField
                        label="State"
                        variant="standard"
                        fullWidth
                        value={address.state_abbreviation}
                        onChange={e => handleInputChange('state_abbreviation', e.target.value)}
                        InputProps={{ disableUnderline: true, style: { fontSize: '1em', fontWeight: '600' } }}
                    />
                    <TextField
                        label="Zipcode"
                        variant="standard"
                        fullWidth
                        value={address.zipcode}
                        onChange={e => handleInputChange('zipcode', e.target.value)}
                        InputProps={{ disableUnderline: true, style: { fontSize: '1em', fontWeight: '600' } }}
                    />
                </AddressRow>
            </FormContainer>
            <AppButton customStyles={{ marginTop: '2em' }} onClick={onSubmit} disabled={!address.address_line_1 || !address.city || !address.state_abbreviation || !address.zipcode}>
                Submit
            </AppButton>
        </AppContainer>
    );
}

export default AddUnitThree;
