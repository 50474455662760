import React, { useContext, forwardRef } from 'react';
import { AlertContext } from "../../contexts/AlertsProvider";
import Alert from '@mui/material/Alert';
import { Snackbar, Slide } from "@mui/material/";
import {useMediaQuery} from "@mui/material";

const AlertMessage = () => {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const { alertMessage, visible, hideAlert } = useContext(AlertContext);

    const SlideTransition = forwardRef(function Transition(props, ref) {
        const { children, ...other } = props;
        const direction = isDesktop ? "left" : "down";
        return (
            <Slide direction={direction} ref={ref} {...other}>
                {children}
            </Slide>
        );
    });

    return (
        visible && (
            <Snackbar
                key={alertMessage.message}
                TransitionComponent={SlideTransition}
                anchorOrigin={
                    isDesktop
                        ? { vertical: 'top', horizontal: 'right' }
                        : { vertical: 'top', horizontal: 'center' }
                }
                open={visible}
                onClose={hideAlert}
            >
                <Alert
                    sx={{
                        borderRadius: '10px',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: '700',
                        width: 'auto',
                        marginTop: '1em'
                    }}
                    elevation={4}
                    severity={alertMessage.type || 'info'}
                    variant="filled"
                >
                    {alertMessage.message}
                </Alert>
            </Snackbar>
        )
    );
};

export default AlertMessage;
