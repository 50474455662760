import React from 'react';
import {Box, Drawer, IconButton, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
        borderTop: '7px solid transparent',
        borderRadius: '25px 25px 0 0',
        height: '90%',
        backgroundColor: '#FCFCFF',
    },
});

const MainDrawerContent = styled(Box)({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
});

function AppDrawer({ children, open, onClose, title }) {
    return (
        <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
            <MainDrawerContent>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" fontWeight="700" color="secondaryText.main">
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon style={{ color: '#37589D', fontSize: 25 }} />
                    </IconButton>
                </Stack>
                {children}
            </MainDrawerContent>
        </StyledDrawer>
    );
}

export default AppDrawer;


