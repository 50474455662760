import React, {useState} from 'react';
import {Box, Card, Grid, IconButton, Paper, Stack, Typography} from "@mui/material";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from '@mui/icons-material/Folder';
import { motion, AnimatePresence } from 'framer-motion';

function ParentGroupCard({ groupName, nGroups, onClick, expanded, groups, onDelete, groupClick, onLongPress }) {
    const [pressTimer, setPressTimer] = useState(null);

    const handlePressStart = () => {
        const timer = setTimeout(() => {
            if (onLongPress) {
                onLongPress();
            }
        }, 350);
        setPressTimer(timer);
    };

    const handlePressEnd = () => {
        if (pressTimer) {
            clearTimeout(pressTimer);
            setPressTimer(null);
        }
    };

    return (
        <motion.div >
            <Card
                onClick={onClick}
                onMouseDown={handlePressStart}
                onMouseUp={handlePressEnd}
                onMouseLeave={handlePressEnd}
                onTouchStart={handlePressStart}
                onTouchEnd={handlePressEnd}
                onTouchCancel={handlePressEnd}
                variant="outlined"
                sx={{
                    borderRadius: '10px',
                    minHeight: expanded ? 'auto' : 130,
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderColor: expanded ? 'success.main' : 'secondary.main',
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" >
                    <FolderCopyIcon color="secondary" sx={{ fontSize: expanded ? '2.25em' : '1.25em' }} />
                    {expanded && (
                        <IconButton onClick={onDelete}>
                            <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                    )}
                </Stack>

                {expanded && (
                    <Box mt={2}>
                        <Typography variant="h6" mb={1} >
                            Groups in {groupName}
                        </Typography>

                        <Grid container spacing={1}>
                            {groups.map((group) => (
                                <Grid item xs={'auto'} key={group.id}>
                                    <Paper elevation={0} sx={{
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        padding: '4px',
                                        border: '1px solid',
                                        borderColor: 'success.main',
                                    }}>
                                        <Stack onClick={() => groupClick(group)} direction="row" justifyContent="space-between" alignItems="center" gap={0.5} >
                                            <FolderIcon color="success" fontSize="small" />
                                            <Typography variant="body2" fontWeight="medium" mr={1}>
                                                {group.name}
                                            </Typography>
                                        </Stack>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
                {!expanded && (
                    <>
                        {/*Better way of doing this..*/}
                        <Box sx={{ flexGrow: 1 }} />
                        <Stack spacing={0}  >
                            <Typography variant="body2" fontWeight="medium">
                                {nGroups} group{nGroups !== 1 ? 's' : ''}
                            </Typography>
                            <Typography variant="body2" fontWeight="medium" >
                                {groupName}
                            </Typography>
                        </Stack>
                    </>
                )}
            </Card>
        </motion.div>
    );
}

export default ParentGroupCard;
