import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useApi} from "./ApiProvider";

const UnitContext = createContext({});

export function UnitProvider({children}) {
    const api = useApi();
    const [units, setUnits] = useState();
    const [unit, setUnit] = useState();
    const [unitGroups, setUnitGroups] = useState([]);
    const [unitType, setUnitType] = useState();
    const [unitData, setUnitData] = useState();
    const [unitFaults,  setUnitFaults] = useState();

    // Add is loading here so I can just use it where needed instead of defining in each file...

    const fetchAllUnits = useCallback(async () => {
        const response = await api.get('/me/units');
        if (response.ok) {
            const unitsWithTypes = response.body.map(unit => ({
                ...unit,
                unitType: unit.unit_model && unit.unit_model.model_number === "Outdoor Unit" ? "Outdoor" : "Indoor",
            }));
            setUnits(unitsWithTypes);
        } else {
            setUnits(null);
        }
        return response;
    }, [api]);

    useEffect(() => {
        const loadUnits = async () => {
            await fetchAllUnits();
        };
        loadUnits();
    }, [fetchAllUnits]);

    // Re-write these functions to efficiently pass data around app and reduce redundant api calls. Callbacks, etc... Create new files

    const fetchUnitGroups = useCallback(async () => {
        const response = await api.get('/me/groups');
        if (response.ok) {
            const groupsWithTypedUnits = response.body.map(group => ({
                ...group,
                units: group.units.map(unit => ({
                    ...unit,
                    unitType: unit.unit_model && unit.unit_model.model_number === "Outdoor Unit" ? "Outdoor" : "Indoor",
                }))
            }));
            setUnitGroups(groupsWithTypedUnits);
        } else {
            setUnitGroups([]);
        }
        return response;
    }, [api]);

    const getSpecificUnitData = useCallback(async (unitId, updateParametersOnly = false) => {
        try {
            const response = await api.get(`/units/${unitId}`);
            console.log(response)
            if (response.ok) {
                if (!updateParametersOnly) {
                    setUnit(response.body.unit);
                }
                setUnitData(response.body.parameters);
            } else {
                setUnit(null);
                setUnitData(null);
            }
            return response;
        } catch (err) {
        }
    }, [api]);

    const getSpecificUnitFaults = useCallback(async (unitId) => {
        try {
            const response = await api.get(`/units/${unitId}/faults`);
            if (response.ok) {
                if (response.body.length === 0) {
                    setUnitFaults(null)
                } else {
                    setUnitFaults(response.body);
                }
            }
            return response;
        } catch (err) {
        }
    }, [api]);

    const writeRegister = useCallback(async (unitId, address, addressType, value) => {
        const processedValue = typeof value === 'boolean' ? value : parseInt(value, 10);
        if (typeof value !== 'boolean' && isNaN(processedValue)) {
            return;
        }
        const payload = { [address]: [addressType, processedValue] };
        const response = await api.put(`/units/${unitId}`, payload);
        console.log(unitId, address, addressType, value);
        console.log(response)
        if (!response.ok) {
            console.error("Error writing register:", response);
        }
        return response;
    }, []);

    const saveGroup = async (groupName, selectedUnits) => {
        const payload = {
            group_name: groupName,
            unit_ids: selectedUnits
        };
        return await api.post('/me/groups', payload);
    };

    const fetchUnitLink = useCallback(async (unitId) => {
        try {
            const payload = { unit_id: unitId };
            const response = await api.post('/make_url', payload);
            if (response.ok) {
                return response.body.url;
            } else {
                console.error("Failed to fetch the unit link:", response);
                return null;
            }
        } catch (error) {
            console.error("Error fetching unit link:", error);
            return null;
        }
    }, [api]);

    return (
        <UnitContext.Provider value={{
            unit,
            setUnit,
            unitType,
            setUnitType,
            units,
            unitGroups,
            setUnits,
            unitData,
            setUnitData,
            unitFaults,
            setUnitFaults,
            writeRegister,
            saveGroup,
            getSpecificUnitData,
            fetchAllUnits,
            fetchUnitGroups,
            getSpecificUnitFaults,
            fetchUnitLink
        }}>
            {children}
        </UnitContext.Provider>
    );
}

export function useUnits() {
    return useContext(UnitContext);
}
