import {Divider, Box} from "@mui/material";

function AppDivider({ inCard = false, customPadding }) {
    return inCard ? (
        <Box style={{width: '90%', margin: '0 auto', padding: customPadding || '0.75em'}}>
            <Divider />
        </Box>
    ) : (
        <Box style={{width: '100%', margin: '0 auto'}}>
            <Divider />
        </Box>
    );
}


export default AppDivider;

