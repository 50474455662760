import {useApi} from "../../contexts/ApiProvider";
import {useCallback, useEffect, useState} from "react";

function UseParentGroups(props) {
    const api = useApi();
    const [parentGroups, setParentGroups] = useState([]);

    const fetchParentGroups = useCallback(async () => {
        const response = await api.get('/me/parent_groups');
        console.log(response);
        if (response.ok) {
            setParentGroups(response.body);
        } else {
            setParentGroups([]);
        }
    }, [api]);

    useEffect(() => {
        fetchParentGroups();
    }, [fetchParentGroups]);

    const saveParentGroup = async (groupName, groupIds) => {
        const data = {
            group_name: groupName,
            group_ids: groupIds
        };
        return await api.post('/me/parent_groups', data);
    };

    const deleteParentGroup = async (groupId) => {
        const data = {
            group_id: groupId,
        }
        return await api.delete('/me/parent_groups', data);
    }

    const editParentGroup = async (groupId, groupsToAdd = [], groupsToRemove = [], nameChange = '') => {
        const data = {
            group_to_edit: groupId,
        };
        if (groupsToAdd.length > 0) {
            data.groups_to_add = groupsToAdd;
        }
        if (groupsToRemove.length > 0) {
            data.groups_to_remove = groupsToRemove;
        }
        if (nameChange) {
            data.name_change = nameChange;
        }
        return await api.put('/me/parent_groups', data);
    };


    return {
        parentGroups,
        fetchParentGroups,
        saveParentGroup,
        editParentGroup,
        deleteParentGroup,
    }

}

export default UseParentGroups;
