import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import styled from '@emotion/styled';

const StyledSearchInputField = styled(TextField)({
    marginTop: '2em',
});

const AppSearchBar = ({ value, onChange, placeholder, endAdornment }) => {
    return (
        <StyledSearchInputField
            variant="standard"
            value={value}
            fullWidth
            onChange={onChange}
            placeholder={placeholder}
            InputProps={{
                endAdornment: endAdornment ? (
                    <InputAdornment position="start">
                        {endAdornment}
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

export default AppSearchBar;
