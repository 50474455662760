import { useState, useEffect } from 'react';
import { useUnits } from '../../contexts/UnitProvider';
import { useApi } from '../../contexts/ApiProvider';

const LOCAL_STORAGE_KEY = 'groupOrder';

const useGroups = (onGroupUpdate) => {
    const { unitGroups, saveGroup, units, fetchUnitGroups } = useUnits();
    const api = useApi();

    const [searchGroupTerm, setSearchGroupTerm] = useState('');
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [orderedGroups, setOrderedGroups] = useState([]);

    const [createGroupDrawerOpen, setCreateGroupDrawerOpen] = useState(false);

    const [groupName, setGroupName] = useState('');
    const [newGroupName, setNewGroupName] = useState('');
    const [isEditingGroupName, setIsEditingGroupName] = useState(false);

    const [selectedGroupUnits, setSelectedGroupUnits] = useState([]);

    const [groupUnitsDrawerOpen, setGroupUnitsDrawerOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [unitsAvailable, setUnitsAvailable] = useState([]);
    const [selectedAvailableUnits, setSelectedAvailableUnits] = useState([]);
    const [addingUnits, setAddingUnits] = useState(false);

    const [filteredCreateGroupUnits, setFilteredCreateGroupUnits] = useState(units);

    useEffect(() => {
        fetchUnitGroups();
    }, [fetchUnitGroups]);


    useEffect(() => {
        const savedOrder = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        if (savedOrder) {
            const ordered = savedOrder
                .map(id => unitGroups.find(group => group.id === id))
                .filter(Boolean);
            const missingGroups = unitGroups.filter(group => !savedOrder.includes(group.id));
            setOrderedGroups([...ordered, ...missingGroups]);
        } else {
            setOrderedGroups(unitGroups);
        }
    }, [unitGroups]);

    useEffect(() => {
        if (searchGroupTerm === '') {
            setFilteredCreateGroupUnits(units);
        } else {
            const term = searchGroupTerm.toLowerCase();
            const filtered = units.filter(unit =>
                unit.serial_number.toLowerCase().includes(term)
            );
            setFilteredCreateGroupUnits(filtered);
        }
    }, [searchGroupTerm, units]);

    useEffect(() => {
        setFilteredGroups(orderedGroups);
    }, [orderedGroups]);

    useEffect(() => {
        if (searchGroupTerm === '') {
            setFilteredGroups(orderedGroups);
        } else {
            const term = searchGroupTerm.toLowerCase();
            setFilteredGroups(
                orderedGroups.filter(group => group.name.toLowerCase().includes(term))
            );
        }
    }, [searchGroupTerm, orderedGroups]);


    const saveOrderToLocalStorage = (newOrder) => {
        const groupIds = newOrder.map(group => group.id);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(groupIds));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(orderedGroups);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setOrderedGroups(items);
        saveOrderToLocalStorage(items);
    };

    const handleCreateGroupDrawer = () => {
        setCreateGroupDrawerOpen(!createGroupDrawerOpen);
    };

    const toggleUnitSelection = (unitId) => {
        setSelectedGroupUnits(prevUnits => {
            if (prevUnits.includes(unitId)) {
                return prevUnits.filter(id => id !== unitId);
            } else {
                return [...prevUnits, unitId];
            }
        });
    };

    const handleSaveGroup = async () => {
        const response = await saveGroup(groupName, selectedGroupUnits);

        if (response.ok) {
            console.log('Successfully created new group');

            setGroupName('');
            setSelectedGroupUnits([]);
            setCreateGroupDrawerOpen(false);

            await fetchUnitGroups();
            if (onGroupUpdate) onGroupUpdate();

        } else {
            console.log('Unable to create new group');
        }
    };

    const handleGroupUnitsDrawer = (group) => {
        setSelectedGroup(group);
        setNewGroupName(group.name);
        setGroupUnitsDrawerOpen(true);
        setAddingUnits(false);
    };

    const handleCloseGroupUnitsDrawer = () => {
        setGroupUnitsDrawerOpen(false);
        setSelectedGroup(null);
        setIsEditingGroupName(false);
    };

    const handleEditGroupName = () => {
        setIsEditingGroupName(true);
    };

    const handleChangeName = async (groupId, oldName, newName) => {
        const data = {
            'group_name': oldName,
            'new_name': newName
        };
        const response = await api.put('/me/groups', data);
        if (response.ok) {
            await fetchUnitGroups();
            setSelectedGroup({ ...selectedGroup, name: newName });
            if (onGroupUpdate) onGroupUpdate();
        } else {
            console.log('Group name not changed.')
        }
    };

    const handleSaveGroupName = async () => {
        if (newGroupName.trim() !== selectedGroup.name) {
            await handleChangeName(selectedGroup.id, selectedGroup.name, newGroupName);
        }
        setIsEditingGroupName(false);
    };

    const handleDeleteGroup = async (groupId) => {
        const data = { 'group_id': groupId };
        const response = await api.delete('/me/groups', data);
        console.log(response)
        console.log(data)
        if (response.ok) {
            await fetchUnitGroups();
            if (onGroupUpdate) onGroupUpdate();
            handleCloseGroupUnitsDrawer();
        } else {
            console.log('Cannot delete group.')
        }
    };

    const handleAddUnits = () => {
        setUnitsAvailable(units.filter(unit => !selectedGroup.units.some(u => u.id === unit.id)));
        setAddingUnits(true);
    };

    const handleFinishAddUnits = async () => {
        await moveUnits('toGroup', selectedAvailableUnits);
        setAddingUnits(false);
    };

    const handleRemoveUnit = async (unitId) => {
        await moveUnits('fromGroup', [unitId]);
        console.log('Removed unit', unitId);
    }

    const moveUnits = async (direction, unitIds = []) => {
        let unitsToAdd = [];
        let unitsToRemove = [];

        if (direction === 'toGroup') {
            if (unitIds.length === 0) {
                console.log('No units selected to add');
                return;
            }
            unitsToAdd = [...unitIds];

            const movedUnits = unitsAvailable.filter(unit => unitIds.includes(unit.id));
            setSelectedGroup({
                ...selectedGroup,
                units: [...selectedGroup.units, ...movedUnits],
            });

            setUnitsAvailable(unitsAvailable.filter(unit => !unitIds.includes(unit.id)));

        } else if (direction === 'fromGroup') {
            if (unitIds.length === 0) {
                console.log('No units selected to remove');
                return;
            }
            unitsToRemove = [...unitIds];

            const remainingUnits = selectedGroup.units.filter(unit => !unitIds.includes(unit.id));
            setSelectedGroup({
                ...selectedGroup,
                units: remainingUnits,
            });

            const movedUnits = selectedGroup.units.filter(unit => unitIds.includes(unit.id));
            setUnitsAvailable([...unitsAvailable, ...movedUnits]);
        }

        try {
            const data = {
                'group_name': selectedGroup.name,
                'unit_ids_add': direction === 'toGroup' ? unitsToAdd : [],
                'unit_ids_remove': direction === 'fromGroup' ? unitsToRemove : [],
            };
            const response = await api.put('/me/groups', data);

            if (response.ok) {
                console.log('Group successfully updated');
                fetchUnitGroups();
                if (onGroupUpdate) onGroupUpdate();
            } else {
                console.log('Failed to update group');
            }
        } catch (error) {
            console.error('An error occurred while updating the group', error);
        }
    };

    const handleToggleUnitSelection = (unitId) => {
        if (selectedAvailableUnits.includes(unitId)) {
            setSelectedAvailableUnits(selectedAvailableUnits.filter(id => id !== unitId));
        } else {
            setSelectedAvailableUnits([...selectedAvailableUnits, unitId]);
        }
    };

    return {
        searchGroupTerm,
        setSearchGroupTerm,
        filteredGroups,
        orderedGroups,
        createGroupDrawerOpen,
        setCreateGroupDrawerOpen,
        groupName,
        setGroupName,
        newGroupName,
        setNewGroupName,
        isEditingGroupName,
        setIsEditingGroupName,
        selectedGroupUnits,
        setSelectedGroupUnits,
        groupUnitsDrawerOpen,
        setGroupUnitsDrawerOpen,
        selectedGroup,
        setSelectedGroup,
        unitsAvailable,
        setUnitsAvailable,
        selectedAvailableUnits,
        setSelectedAvailableUnits,
        addingUnits,
        setAddingUnits,
        filteredCreateGroupUnits,
        setFilteredCreateGroupUnits,

        handleOnDragEnd,
        handleCreateGroupDrawer,
        toggleUnitSelection,
        handleSaveGroup,
        handleGroupUnitsDrawer,
        handleCloseGroupUnitsDrawer,
        handleEditGroupName,
        handleChangeName,
        handleSaveGroupName,
        handleDeleteGroup,
        handleAddUnits,
        handleFinishAddUnits,
        handleRemoveUnit,
        moveUnits,
        handleToggleUnitSelection,
    };
};

export default useGroups;
