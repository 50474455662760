import React, {useState} from 'react';
import AppContainer from "../components/shared/AppContainer";
import {Box} from "@mui/material";
import AddUnitOne from "../components/addUnit/AddUnitOne";
import AddUnitTwo from "../components/addUnit/AddUnitTwo";
import {useNavigate} from "react-router-dom";
import {useApi} from "../contexts/ApiProvider";
import {useAlert} from "../contexts/AlertsProvider";
import AddUnitThree from "../components/addUnit/AddUnitThree";

function AddUnitScreen(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [userChoice, setUserChoice] = useState(null);
    const [unitInfo, setUnitInfo] = useState(null);
    const [unitLocation, setUnitLocation] = useState('');
    const api = useApi();
    const alert = useAlert();
    const navigate = useNavigate();

    const handleChoiceChange = (value) => {
        setUserChoice(value);
    };

    const handleSerialChange = (value) => {
        setUnitInfo(value);
    };

    const handleLocationChange = (value) => {
        setUnitLocation(value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async () => {
        if (!unitInfo || unitInfo.trim() === '') {
            alert('Serial number cannot be empty', 'error');
            return;
        }

        const requestBody = {
            serial_number: unitInfo,
            application: userChoice,
        };

        if (Object.keys(unitLocation).length) {
            requestBody.address = unitLocation;
        }

        console.log(requestBody)
        const response = await api.post('/me/units', requestBody);
        console.log(response);

        if (response.ok) {
            alert('Unit successfully added', 'success');
            if (response.body.first_install === true) {
                const unitId = response.body.id;
                navigate(`/dashboard/my_units/${unitId}/assistant`);
            } else {
                navigate('/dashboard/my_units');
            }
        } else if (response.status === 400) {
            alert('Please enter the units location', 'info');
            handleNext();
        } else if (response.status === 403) {
            alert('This unit already belongs to you', 'info');
        } else {
            alert('Unit not found', 'error');
        }
    };

    return (
        <AppContainer>
            <Box display="flex">
                {activeStep === 0 && <AddUnitOne onNext={handleNext} onChoiceChange={handleChoiceChange}/>}
                {activeStep === 1 && <AddUnitTwo onBack={handleBack} onSubmit={handleSubmit} onSerialChange={handleSerialChange} />}
                {activeStep === 2 && <AddUnitThree onBack={handleBack} onSubmit={handleSubmit} onLocationChange={handleLocationChange} />}
            </Box>
        </AppContainer>
    );
}

export default AddUnitScreen;

